@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.contractexample {
    position: relative;
    .editorDiv{
        border: 1px solid black;
        padding: 5px;
        .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr{
            background-color: #ffffff;
        }
    }
    .ant-table-thead > tr > th {
        background: rgb(46,167,178);
        color: #fff;
    }
}