.contractview {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 3vmin 5vmin;
    user-select: none;
    .title {
        width: 100%;
        background: #243561;
        color: #d7ab4b;
        font-weight: 900;
        font-size: 45px;
        text-align: center;
        line-height: 70px;
    }
    .statement {
        margin-top: 25px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        background: #ebddd4;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .content {
        .signimg {
            width: 100%;
        }
        .companyimg {
            width: 100px;
            position: absolute;
            right: 0;
            top: 0;
        }
        .info {
            font-size: 18px;
            margin-top: 20px;
            font-weight: 500;
        }
        .date {
            font-size: 18px;
            font-weight: 400;
            margin-top: 20px;
            text-align: center;
            text-align-last: justify;
            text-justify: distribute-all-lines;
        }
        .signArea {
            margin-top: 20px;
            width: 100%;
            .signtureImage {
                border: 1px solid #000;
                width: 100%;
            }
            .signatureCanvas {
                border: 1px solid #000;
                // width: 100%;
            }
        }
    }
}