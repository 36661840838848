@import '~antd/dist/antd.css';

.App {
  text-align: center;
  .userInfo{
    text-align: right;
    color: #fff;
    font-size: 16px;
    padding: 0 10px;
    .logout {
      float: right;
      margin-left: 20px;
      cursor: pointer;
      color: #11f3e9;
      font-weight: 600;
    }
  }
}
